import React from "react";

const CaretTopRightIcon = ({ size = 24, props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            {...props}
        >
            <polyline points="6 6 18 6 18 18" />
        </svg>
    );
};

export default CaretTopRightIcon;
