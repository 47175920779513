import React, { useState } from "react";
import { Grow, IconButton, Tooltip } from "@mui/material";
import { IoLogoGithub } from "react-icons/io5";
import { LiaLinkedinIn } from "react-icons/lia";
import { TfiEmail } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

const Footer = ({ downloadResume }) => {
    const sections = ["Home", "Experience", "Projects", "About Me"];
    const [isCopied, setIsCopied] = useState(false);

    const copyEmail = () => {
        navigator.clipboard.writeText("benjamin.kamin.81@gmail.com");
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000); // Close the tooltip after 2 seconds
    };

    const theme = useTheme();

    return (
        <footer className="py-8 px-4 bg-background">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-8">
                {/* Left: Navigation Links */}
                <div className="flex flex-col space-y-2 items-center md:items-start">
                    <h4 className="font-bold text-md w-min">Navigation</h4>
                    {sections.map((section, index) => (
                        <button
                            key={index}
                            onClick={() => {
                                const element = document.getElementById(
                                    section.toLowerCase()
                                );
                                element.scrollIntoView({ behavior: "smooth" });
                            }}
                            className="hover:text-gray-400 text-left w-min text-nowrap"
                        >
                            {section}
                        </button>
                    ))}
                </div>

                {/* Center: Social Media Links */}
                <div className="flex flex-col space-y-2 items-center">
                    <h4 className="font-bold text-md">Connect with Me</h4>
                    <div className="flex space-x-4">
                        <Link
                            to="https://github.com/BenKamin03"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconButton disableRipple>
                                <IoLogoGithub className="w-6 h-6 text-primary" />
                            </IconButton>
                        </Link>
                        <Link
                            to="https://linkedin.com/in/benjamin-kamin/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconButton disableRipple>
                                <LiaLinkedinIn className="w-6 h-6 bg-primary text-background" style={{zIndex: 0}} />
                            </IconButton>
                        </Link>
                        <Tooltip
                            title={isCopied && "Email Copied!"}
                            open={isCopied}
                            onClose={() => setIsCopied(false)}
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, -14],
                                            },
                                        },
                                    ],
                                },
                            }}
                            TransitionComponent={Grow}
                            TransitionProps={{
                                timeout: 300,
                            }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        borderRadius: 0, // Remove the border radius
                                        backgroundColor: "transparent",
                                        color: theme.palette.primary.main
                                    },
                                },
                            }}
                        >
                            <IconButton disableRipple onClick={copyEmail}>
                                <TfiEmail className="w-6 h-6 text-primary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

                {/* Right: Resume Button */}
                <div className="flex flex-col md:items-end items-center">
                    <h4 className="font-bold text-md cursor-pointer select-none" onClick={downloadResume}>Resume</h4>
                </div>
            </div>

            {/* Copyright */}
            <div className="border-t border-gray-700 mt-8 pt-4 text-center text-sm">
                © 2024 Benjamin Kamin. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
