import { Box, Button, Grow, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaPenFancy } from "react-icons/fa";
import { GiOpenBook, GiSoccerBall } from "react-icons/gi";
import {
    IoChevronBackSharp,
    IoChevronForwardSharp,
    IoCodeSlashSharp,
    IoGameControllerSharp,
    IoMusicalNoteSharp,
} from "react-icons/io5";
import { useTheme } from "@emotion/react";
import Modal from "../../components/modal/Modal";
import { MdOndemandVideo } from "react-icons/md";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const AboutMe = ({ closeModalEffect }) => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const url = window.location.href;
        if (url.includes("About")) {
            if (url.includes("Video")) {
                setIsVideoOpen(true);
            }
        } else {
            setIsVideoOpen(false);
        }
    }, [window.location.href]);

    const getAge = () => {
        const birthDate = new Date(2003, 9, 14); // October is month 9 in JavaScript Date (0-indexed)
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const age = getAge();

    return (
        <>
            <div
                id="about"
                className="bg-primary text-background grid-cols-1 md:grid-cols-5 grid justify-center items-center"
                style={{ minHeight: "calc(100vh + 64px)" }}
            >
                <div
                    id="about me"
                    className="h-full bg-background px-12 flex justify-center col-span-2 items-center md:order-1 order-2 flex-col relative"
                >
                    <img
                        src="/assets/images/portrait/portrait.jpg"
                        alt="Portrait"
                        className="object-contain h-fit md:my-0 my-32 max-h-[70vh]"
                    />
                    <div
                        className="w-full bg-background h-[0.0625rem] absolute bottom-0 block md:hidden"
                        style={{ translate: "translateY(50%)" }}
                    >
                        <div className="h-[0.0625rem] bg-primary mx-4" />
                    </div>
                </div>
                <div className="h-full col-span-3 flex justify-center items-center flex-col space-y-2 md:space-y-4 py-12 md:order-2 order-1">
                    <h1 className="text-4xl font-bold w-full px-12">
                        About Me
                    </h1>
                    <div className="w-full h-[0.0625rem] bg-background" />
                    <div className="mx-12 space-y-2 font-sans">
                        <p>
                            I am a {age} year old Full Stack Software Engineer and Web Developer
                            based in San Francisco Bay Area. I am passionate about
                            building high-quality software and web applications
                            that are user-friendly, accessible and unique. I
                            have experience working with a variety of
                            technologies, including React, Node.js, Express,
                            MongoDB, MySQL, and more.
                        </p>
                        <p className="">
                            <span>
                                Outside of Software, I am also interested in
                                soccer, music and video games. I've been playing
                                soccer since I was 4 and I've been playing video
                                games since I was 8. I love competition and I
                                love to win. I got really good at a video game
                                called Rocket League, where I competed to be in
                                the top 48 teams in North America for several
                                years. If you want to take a look at some
                                highlights of mine:{""}
                            </span>
                            <button
                                className="inline-block align-middle px-4"
                                onClick={() => {
                                    setIsVideoOpen(true);
                                    navigate("/About/Video");
                                }}
                            >
                                <MdOndemandVideo />
                            </button>
                        </p>
                    </div>
                </div>
            </div>
            <Modal
                open={isVideoOpen}
                onClose={() => setIsVideoOpen(false)}
                percent={100}
                closeIcon
                direction="top"
            >
                <div className="w-full h-full flex justify-center items-center z-[1] absolute inset-0">
                    <ReactPlayer
                        url="/assets/video/rocket_league/montage.mp4"
                        controls
                        width={"80vmin"}
                        volume={0}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
                <div
                    className="h-full absolute bottom-0 right-0 bg-primary z-0"
                    style={{ width: "80%", height: "80%" }}
                />
            </Modal>
        </>
    );
};

export default AboutMe;
