import React from "react";

const CIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 760 843.18"
        width="1.5rem"
        height="1.5rem"
        {...props} // allows for additional props like color
    >
        <path
            d="M498.919 493.873L520.983 506.612L619.33 563.393C570.531 644.61 481.567 699 380 699C226.016 699 101 573.984 101 420L101 420C101 266.016 226.016 141 380 141C481.567 141 570.531 195.39 619.33 276.607L619.33 276.607L520.983 333.388L498.919 346.127C474.196 306.437 430.161 280 380 280C302.732 280 240 342.732 240 420C240 497.268 302.732 560 380 560C430.161 560 474.196 533.563 498.919 493.873ZM742.764 646.32L570 744.75L397.236 843.18C387.723 848.6 372.277 848.6 362.764 843.18L190 744.75L17.236 646.32C7.723 640.9 0 627.611 0 616.662L0 420L0 223.338C0 212.389 7.723 199.1 17.236 193.68L190 95.25L362.764 -3.18C372.277 -8.6 387.723 -8.6 397.236 -3.18L570 95.25L742.764 193.68C752.277 199.1 760 212.389 760 223.338L760 420L760 616.662C760 627.611 752.277 640.9 742.764 646.32L742.764 646.32L742.764 646.32Z"
            fill="currentColor" // Use currentColor to inherit CSS color
            fillRule="evenodd"
        />
    </svg>
);

export default CIcon;
