import React, { useEffect, useRef } from "react";
import { IoChevronBackSharp } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";

/**
 *
 * @param {boolean} open whether the modal is open
 * @param {Function} onClose a function that gets called when the modal wants to close
 * @param {*} children the children components
 * @param {Object} sx additional styling effects
 * @param {Number} percent the percent of the screen the modal takes up
 * @param {boolean} closeIcon whether to include a close icon or not
 * @param {string} background the background color of the modal - in tailwindcss format
 * @param {string} direction the direction the modal should slide in from [left, right, top, bottom]
 */
const Modal = ({
    open,
    onClose,
    children,
    sx,
    percent,
    closeIcon,
    background,
    direction,
}) => {
    const navigate = useNavigate();

    const close = (e) => {
        if (window.location.pathname === '/') {
            onClose(e);
            return;
        }
        navigate('/');
        onClose(e);
    };

    useEffect(() => {
        if (open) {
            // Disable scrolling when the modal is open
            document.body.style.overflow = "hidden";
        } else {
            // Re-enable scrolling when the modal is closed
            document.body.style.overflow = "auto";
        }

        // Clean up when the modal is unmounted or when it closes
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [open]);

    const getDirection = () => {
        switch (direction) {
            case "left":
                return "-translate-x-full";
            case "right":
                return "translate-x-full";
            case "top":
                return "-translate-y-full";
            case "bottom":
                return "translate-y-full";
            default:
                return "-translate-x-full";
        }
    }

    return (
        <div
            onScrollCapture={(e) => e.stopPropagation()}
            onClick={close}
            className={`fixed inset-0 z-30 w-full h-full flex justify-center items-center transition-colors ${
                open ? "visible" : "invisible"
            } ${percent !== 100 ? "bg-black bg-opacity-50" : ""}`}
        >
            <div
                className={`${
                    background ? background : "bg-white"
                } rounded-xl transition-all duration-500 pt-[64px] shadow-lg ${
                    open ? "translate-x-0 translate-y-0" : getDirection()
                }`}
                onClick={(e) => e.stopPropagation()}
                style={{ width: `${percent}vw`, height: `${percent}vh`, ...sx }}
            >
                {closeIcon && (
                    <button
                        className="group flex justify-center items-center space-x-2 hover:scale-105 transition-all p-2 absolute top-20 left-8 z-10"
                        onClick={close}
                    >
                        <div className="transition-none group-hover:animate-bounce-horizontal transition-all">
                            <IoChevronBackSharp />
                        </div>
                        <p className="transition-none">Back</p>
                    </button>
                )}
                {children}
            </div>
        </div>
    );
};

export default Modal;
