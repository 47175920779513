import React, { useEffect, useState } from "react";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import ProjectCard from "../../components/cards/ProjectCard";
import { FaCss3, FaCss3Alt, FaHtml5, FaJava, FaReact } from "react-icons/fa";
import { IoIosSettings, IoLogoJavascript, IoMdPlay } from "react-icons/io";
import { RiTailwindCssFill } from "react-icons/ri";
import { DiMongodb } from "react-icons/di";
import { SiEclipsejetty, SiExpress, SiNodedotjs } from "react-icons/si";
import CIcon from "../../icons/CIcon";
import Modal from "../../components/modal/Modal";
import { Button, Grow, IconButton, Tooltip } from "@mui/material";
import { IoChevronBackSharp, IoPauseSharp } from "react-icons/io5";
import { TbBrandGithubFilled } from "react-icons/tb";
import { BsGithub } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import Carousel from "../../components/carousel/Carousel";
import CaretTopRightIcon from "../../icons/TRCaret";
import SearchEngine from "./projects/SearchEngine";
import RecipeHub from "./projects/RecipeHub";
import NoiseGenerator from "./projects/NoiseGenerator";
import Server from "./projects/Server";

const icons = {
    java: <FaJava size={"1.5rem"} />,
    html: <FaHtml5 size={"1.5rem"} />,
    css: <FaCss3Alt size={"1.5rem"} />,
    jetty: <SiEclipsejetty size={"1.5rem"} />,
    react: <FaReact size={"1.5rem"} />,
    javascript: <IoLogoJavascript size={"1.5rem"} />,
    tailwind: <RiTailwindCssFill size={"1.5rem"} />,
    mongodb: <DiMongodb size={"1.5rem"} />,
    express: <SiExpress size={"1.5rem"} />,
    node: <SiNodedotjs size={"1.5rem"} />,
    c: <CIcon />,
    makefile: <IoIosSettings size={"1.5rem"} />,
};

const Projects = ({ closeModalEffect }) => {
    const navigate = useNavigate();

    const projects = [
        {
            name: "Search Engine",
            bio: "A Full Stack Search Engine project built using Java in the back end which crawls, indexes, and ranks web pages based on query relevance. It uses React, MUI and Tailwind CSS in the front end.",
            src: "/assets/images/projects/search_engine_1.png",
            images: [
                "/assets/images/projects/search_engine_1.png",
                "/assets/images/projects/search_engine_2.png",
                "/assets/images/projects/search_engine_3.png",
                "/assets/images/projects/search_engine_4.png",
            ],
            languages: {
                Java: icons.java,
                Jetty: icons.jetty,
                React: icons.react,
                TailwindCSS: icons.tailwind,
            },
        },
        {
            name: "Recipe Social Media Web App",
            bio: "A full-stack application that enables users to post, rate, and comment on recipes, manage accounts, and favorite recipes, all within a responsive design using Tailwind CSS.",
            src: "/assets/images/projects/recipe_hub_1.png",
            images: [
                "/assets/images/projects/recipe_hub_1.png",
                "/assets/images/projects/recipe_hub_2.png",
                "/assets/images/projects/recipe_hub_3.png",
                "/assets/images/projects/recipe_hub_4.png",
                "/assets/images/projects/recipe_hub_5.png",
            ],
            languages: {
                MongoDB: icons.mongodb,
                Express: icons.express,
                React: icons.react,
                Node: icons.node,
                TailwindCSS: icons.tailwind,
            },
        },
        {
            name: "Server in C",
            bio: "A basic HTTP server written in C that handles GET requests, serves files to clients, and manages multiple connections using non-blocking sockets and the poll function",
            src: "/assets/images/projects/server.png",
            languages: {
                C: icons.c,
                Makefile: icons.makefile,
            },
        },
        {
            name: "Noise Generator",
            bio: "A dynamic Web App that generates and visualizes Perlin, Worley, White, and Value noise patterns using the HTML Canvas element for procedural texture creation.",
            src: "/assets/images/projects/noise_generator_2.png",
            images: [
                "/assets/images/projects/noise_generator_2.png",
                "/assets/images/projects/noise_generator_1.png",
                "/assets/images/projects/noise_generator_3.png",
                "/assets/images/projects/noise_generator_4.png",
            ],
            languages: {
                JavaScript: icons.javascript,
                HTML: icons.html,
                CSS: icons.css,
            },
        },
    ];

    const theme = useTheme();

    const [openProject, setOpenProject] = useState(-1);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        setOpenProject(-1);
    }, [closeModalEffect]);

    useEffect(() => {
        const url = window.location.href;
        if (url.includes("Projects")) {
            projects.forEach((proj, index) => {
                if (url.includes(proj.name.replaceAll(" ", "-"))) {
                    setOpenProject(index);
                }
            });
        } else {
            setOpenProject(-1);
        }
    }, [window.location.href]);

    return (
        <>
            <div
                id="projects"
                className="bg-background text-primary py-12 px-12 overflow-clip min-h-screen flex-col hidden md:flex justify-center items-center"
            >
                <div className=" h-full w-full flex justify-center items-center space-x-4">
                    <div className="w-full bg-primary h-[.0625rem]" />
                    <h1 className="text-2xl">Projects</h1>
                    <div className="w-full bg-primary h-[.0625rem]" />
                </div>
                <div className="flex justify-center items-center h-full">
                    <div
                        className="[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)] overflow-hidden"
                        style={{
                            maxWidth:
                                (300 * projects.length * 2 +
                                    (projects.length * 2 - 1) * 16) /
                                2,
                        }}
                    >
                        <div
                            className="inline-flex flex-nowrap py-4"
                            style={{
                                width:
                                    300 * projects.length * 2 +
                                    (projects.length * 2 - 1) * 16,
                            }}
                        >
                            <ul
                                className="grid grid-cols-4 [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll px-2 gap-4"
                                style={{
                                    animationPlayState:
                                        openProject == -1 && !isPaused
                                            ? ""
                                            : "paused",
                                }}
                            >
                                {projects.map((project, index) => (
                                    <ProjectCard
                                        openProject={() => {
                                            setOpenProject(index);
                                            navigate(
                                                `/Projects/${project.name.replaceAll(
                                                    " ",
                                                    "-"
                                                )}`
                                            );
                                        }}
                                        key={index}
                                        project={project}
                                    />
                                ))}
                            </ul>
                            <ul
                                className="grid grid-cols-4 [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll px-2 delay-[30s] gap-4"
                                style={{
                                    animationPlayState:
                                        openProject == -1 && !isPaused
                                            ? ""
                                            : "paused",
                                }}
                            >
                                {projects.map((project, index) => (
                                    <ProjectCard
                                        openProject={() => {
                                            setOpenProject(index);
                                            navigate(
                                                `/Projects/${project.name.replaceAll(
                                                    " ",
                                                    "-"
                                                )}`
                                            );
                                        }}
                                        key={index}
                                        project={project}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className=" h-full w-full flex justify-center items-center space-x-4">
                    <div className="w-full bg-primary h-[.0625rem]" />
                    <button
                        onClick={() => setIsPaused((b) => !b)}
                        className="transition-all hover:scale-110 duration-500 "
                    >
                        {isPaused ? (
                            <IoMdPlay size={"2rem"} />
                        ) : (
                            <IoPauseSharp size={"2rem"} />
                        )}
                    </button>
                    <div className="w-full bg-primary h-[.0625rem]" />
                </div>
            </div>
            <div className="min-h-screen bg-slate-200 w-full flex justify-center items-center md:hidden flex-col">
                <div className="flex w-full space-x-4 justify-center items-center px-4">
                    <div className="w-full h-[.0625rem] bg-primary" />
                    <h1 className="text-2xl">Projects</h1>
                    <div className="w-full h-[.0625rem] bg-primary" />
                </div>
                <Carousel>
                    {projects.map((project, index) => (
                        <ProjectCard
                            openProject={() => {
                                setOpenProject(index);
                                navigate(
                                    `/Projects/${project.name.replaceAll(
                                        " ",
                                        "-"
                                    )}`
                                );
                            }}
                            key={index}
                            project={project}
                        />
                    ))}
                </Carousel>
                <div className="flex w-full space-x-4 justify-center items-center px-4">
                    <div className="w-full h-[.0625rem] bg-primary" />
                </div>
            </div>
            <SearchEngine
                project={projects[0]}
                open={openProject === 0}
                onClose={() => setOpenProject(-1)}
            />
            <RecipeHub
                project={projects[1]}
                open={openProject === 1}
                onClose={() => setOpenProject(-1)}
            />
            <Server
                project={projects[2]}
                open={openProject === 2}
                onClose={() => setOpenProject(-1)}
            />
            <NoiseGenerator
                project={projects[3]}
                open={openProject === 3}
                onClose={() => setOpenProject(-1)}
            />
        </>
    );
};

export default Projects;
