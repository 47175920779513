import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../components/modal/Modal";
import { FaCss3Alt, FaHtml5, FaJava, FaReact } from "react-icons/fa";
import { SiEclipsejetty, SiExpress, SiFastapi } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io";
import { RiTailwindCssFill } from "react-icons/ri";
import { DiMongodb } from "react-icons/di";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { TbSql } from "react-icons/tb";
import { Link } from "react-router-dom";
import CaretTopRightIcon from "../../../icons/TRCaret";
import {
    IoCaretDownSharp,
    IoCaretUpSharp,
    IoCloseSharp,
} from "react-icons/io5";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineSeparator,
} from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { MdOutlineClose } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";

const PrimroseAdmissions = ({ open, onClose }) => {
    const observer = useRef(null);
    const ref = useRef(null);
    const ref2 = useRef(null);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [isRef2AtTop, setIsRef2AtTop] = useState(false); // Track if ref2 is at the top
    const [width, setWidth] = useState(window.innerWidth);

    const startDate = new Date("2024-02-26");
    const endDate = new Date();

    const getDifferenceInDays = (start, end) => {
        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
        return Math.round(Math.abs((end - start) / oneDay));
    };

    const differenceInDays = getDifferenceInDays(startDate, endDate);

    const getDifferenceInYearsAndMonths = (start, end) => {
        const totalMonths =
            (end.getFullYear() - start.getFullYear()) * 12 +
            (end.getMonth() - start.getMonth());
        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;
        return `${years > 0 ? `${years} Year${years > 1 ? "s" : ""}` : ""} ${
            months && `${months} Month${months > 1 ? "s" : ""}`
        }`.trim();
    };

    const differenceInYearsAndMonths = getDifferenceInYearsAndMonths(
        startDate,
        endDate
    );

    const icons = {
        ReactJS: <FaReact size={"1.5rem"} />,
        Javascript: <IoLogoJavascript size={"1.5rem"} />,
        TailwindCSS: <RiTailwindCssFill size={"1.5rem"} />,
    };

    useEffect(() => {
        if (open) {
            ref.current.scrollTop = 0;
        }
    }, [open]);

    const positions = [
        {
            title: "Software Engineering Contractor",
            dates: "08/2024 - Present",
            projects:
                "Currently developing a configuration generator for the Radio Unit (RU) and designing a GUI interface to streamline the setup and customization process.",
        },
        {
            title: "Software Engineering Intern",
            dates: "06/2024 - 08/2024",
            projects:
                "Developed a new Landing Page for Upper Management to have eyes into the lab team's progress, updated the Serial Log Page to improve the user experience for the lab team, developed a Mac Address Page to allow Management to keep track of utilized Mac Addresses, reworked Front End's API call structure to improve code quality and readability, and updated the Reworks Page to allow for easier tracking of reworks.",
        },
        {
            title: "Software Engineering Contractor",
            dates: "02/2024 - 06/2024",
            projects:
                "Enhanced MUI's Data Grid to add custom functionality for our lab teams to use, and provided a Web Developer perspective to the Marketing and BD Team for their website revamps.",
        },
    ];

    useEffect(() => {
        const createObserver = () => {
            if (observer.current) {
                observer.current.disconnect();
            }

            if (
                ref2.current.getBoundingClientRect().height >=
                window.innerHeight - 96
            ) {
                observer.current = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                setIsRef2AtTop(true);
                            } else {
                                setIsRef2AtTop(false);
                            }
                        });
                    },
                    {
                        threshold: Math.min(
                            (window.innerHeight - 96) /
                                ref2.current.getBoundingClientRect().height,
                            1
                        ),
                    }
                );

                observer.current.observe(ref2.current);
            } else {
				setIsRef2AtTop(false);
			}
        };

        createObserver();

        const handleResize = () => {
            createObserver();
        };

        window.addEventListener("resize", handleResize);

        const resizeObserver = new ResizeObserver(() => {
            createObserver();
        });

        resizeObserver.observe(ref2.current);

        return () => {
            window.removeEventListener("resize", handleResize);
            resizeObserver.disconnect();
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [ref2]);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const theme = useTheme();

    return (
        <Modal
            open={open}
            onClose={onClose}
            background={
                isRef2AtTop
                    ? "bg-background text-background"
                    : "bg-background text-primary"
            }
            percent={100}
            closeIcon={width >= 768}
            direction={"top"}
            sx={{ borderRadius: 0 }}
        >
            <div ref={ref} className="h-full overflow-auto text-primary">
                <div
                    className="h-[90%] ml-12 md:ml-32 lg:ml-64  pr-12 md:pr-32 lg:pr-64 bg-primary transition-all duration-1000 top-0 left-0 flex items-end justify-center relative"
                    style={{
                        transform: open
                            ? "translateY(0)"
                            : "translateY(-100vh)",
                        transitionDelay: open ? ".5s" : "0s",
                    }}
                >
                    <button
                        className="absolute top-2 right-2 text-xl text-background sm:hidden block"
                        onClick={onClose}
                    >
                        <IoCaretUpSharp size={"2rem"} />
                    </button>

                    <Link
                        to="https://www.primroseadmissions.com/"
                        target="_blank"
                        className="text-background mb-12 text-6xl lg:text-9xl xl:text-[12rem] group relative"
                    >
                        <h1 className="px-6">Primrose</h1>
                        <p className="text-base lg:bottom-[-5%] mx-6 absolute">
                            3 Months
                        </p>
                        <div className="group-hover:opacity-100 absolute top-0 right-0 opacity-0 transition-all">
                            <CaretTopRightIcon />
                        </div>
                    </Link>
                </div>
                <div className="ml-4 md:mx-32 lg:ml-64 mr-4 lg:mr-64 flex justify-center items-center flex-col">
                    <IconButton
                        sx={{ color: theme.palette.primary.main }}
                        disableRipple
                        className="hover:animate-bounce-vertical-down"
                        onClick={(e) => {
                            e.preventDefault();
                            ref.current.scrollTo({
                                top:
                                    ref.current.getBoundingClientRect().height -
                                    64,
                                behavior: "smooth",
                            });
                        }}
                    >
                        <IoCaretDownSharp
                            size={"3rem"}
                            className="transition-all duration-1000"
                            style={{
                                opacity: open ? 1 : 0,
                                transitionDelay: open ? ".5s" : "0s",
                            }}
                        />
                    </IconButton>
                    <h1
                        className="text-2xl lg:text-4xl my-12 lg:my-24 max-w-xl text-center"
                        style={{ wordSpacing: "-.25rem" }}
                    >
                        I developed a website for Primrose Admissions, a college
                        consulting company, to provide a advertising platform
                        for their services.
                    </h1>
                    <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-12 mb-12 mt-6 justify-center">
                        <div className="flex flex-col space-y-2">
                            <h1 className="flex w-fit px-2 border-b-2 border-primary text-xl">
                                Role
                            </h1>
                            <p className="text-nowrap ml-2 font-sans">
                                Contract Web Developer
                            </p>
                        </div>
                        <div className="flex flex-col space-y-2">
                            <h1 className="flex w-fit px-2 border-b-2 border-primary text-xl">
                                Using
                            </h1>
                            <div className="ml-2 font-sans flex space-x-4">
                                {Object.keys(icons).map((key, index) => (
                                    <Tooltip
                                        title={key}
                                        key={index}
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: "offset",
                                                        options: {
                                                            offset: [0, -10],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                        TransitionProps={{
                                            timeout: 300,
                                        }}
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    borderRadius: 0, // Remove the border radius
                                                    backgroundColor:
                                                        "transparent",
                                                    color: theme.palette.primary
                                                        .main,
                                                },
                                            },
                                        }}
                                    >
                                        <div className="hover:scale-110 transition-all">
                                            {icons[key]}
                                        </div>
                                    </Tooltip>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="space-y-24 mx-12 mt-12 md:mt-24">
                        <img
                            src="/assets/images/primrose/primrose_1.png"
                            alt="Primrose Admissions Landing Page"
                            style={{ aspectRatio: "1920/1847" }}
                            className="w-full"
                        />
                        <div>
                            <h1 className="text-center text-3xl">
                                Modern & Streamlined
                            </h1>
                        </div>
                    </div>
                </div>
                <div ref={ref2} className="w-full pr-4 md:pr-32 lg:pr-64">
                    <div className="bg-primary pb-12 h-full w-full">
                        <div className="ml-4 md:ml-32 lg:ml-64 flex justify-center items-center flex-col">
                            <p className="text-center text-background w-full mb-24 font-sans">
                                The design focuses the users on the quality of
                                the services.
                            </p>
                            <img
                                src="/assets/images/primrose/primrose_2.png"
                                alt="Primrose Admissions Testimonials"
                                className="px-12 w-full"
                                style={{ aspectRatio: "1920/2125" }}
                            />

                            <button
                                className="text-background mt-12 group flex"
                                onClick={(e) => {
                                    e.preventDefault();
                                    ref.current.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    });
                                }}
                            >
                                <IoCaretUpSharp className="group-hover:animate-bounce-vertical mr-2 mt-1" />
                                Scroll To Top
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PrimroseAdmissions;
