import React, { useEffect, useRef } from "react";
import Modal from "../../../components/modal/Modal";
import { IconButton, Tooltip } from "@mui/material";
import { FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

const SearchEngine = ({ project, open, onClose }) => {
    const descriptions = [
        {
            title: "Web Crawling",
            description:
                "Downloads pages from the web for indexing, finding all of the links using regexes on the downloaded pages to recursively traverse through pages.",
        },
        {
            title: "Stemming and Inverted Index",
            description:
                "Reduces words to their root form to enhance search accuracy. Organizes stemmed words with references to the pages they occur on.",
        },
        {
            title: "Relevance Scoring",
            description:
                "Ranks pages based on query matches to the Inverted Index.",
        },
        {
            title: "Front-End Integration",
            description:
                "Displays the search results in an intuitive, user-friendly format, which includes dark mode.",
        },
    ];

    // Create an array that alternates between text and images
    const combinedContent = [],
        combinedContentMobile = [];
    const maxLength = Math.max(project.images.length, descriptions.length);

    for (let i = 0; i < maxLength; i++) {
        if (i % 2 === 0) {
            if (i < descriptions.length) {
                combinedContent.push({
                    type: "description",
                    content: descriptions[i],
                });
            }
            if (i < project.images.length) {
                combinedContent.push({
                    type: "image",
                    content: project.images[i],
                });
            }
        } else {
            if (i < project.images.length) {
                combinedContent.push({
                    type: "image",
                    content: project.images[i],
                });
            }
            if (i < descriptions.length) {
                combinedContent.push({
                    type: "description",
                    content: descriptions[i],
                });
            }
        }

        if (i < project.images.length) {
            combinedContentMobile.push({
                type: "image",
                content: project.images[i],
            });
        }
        if (i < descriptions.length) {
            combinedContentMobile.push({
                type: "description",
                content: descriptions[i],
            });
        }
    }

    const projectRef = useRef(null);

    useEffect(() => {
        if (open) {
            projectRef.current.scrollTop = 0;
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            percent={100}
            background={"bg-background pt-32"}
            closeIcon
        >
            <div className="p-6 pt-24 overflow-y-auto h-full">
                <h1 className="text-3xl text-primary absolute top-20 w-full text-nowrap inset-0 h-min text-center">
                    {project.name}
                </h1>
                <Link target="_blank" to="https://github.com/BenKamin03/SearchEngine">
                    <IconButton
                        disableRipple
                        sx={{
                            position: "absolute",
                            top: "5rem",
                            right: "2rem",
                            color: useTheme().palette.primary.main,
                        }}
                    >
                        <FaGithub />
                    </IconButton>
                </Link>
                <div ref={projectRef} className="w-full h-full overflow-y-auto">
                    <div className="flex justify-center items-center w-full space-x-4">
                        <div className="flex justify-center items-center w-4/5 space-x-4">
                            <div
                                className={`h-[.0625rem] bg-primary transition-all ${
                                    open
                                        ? "w-full transition-all duration-[2.5s] delay-500 ease-linear"
                                        : "w-0"
                                }`}
                            />
                            <div className="flex space-x-4">
                                {project.languages &&
                                    Object.keys(project.languages).map(
                                        (language, index) => (
                                            <Tooltip
                                                key={index}
                                                title={language}
                                            >
                                                <div>
                                                    {
                                                        project.languages[
                                                            language
                                                        ]
                                                    }
                                                </div>
                                            </Tooltip>
                                        )
                                    )}
                            </div>
                            <div
                                className={`h-[.0625rem] bg-primary transition-all ${
                                    open
                                        ? "w-full transition-all duration-[2.5s] delay-500 ease-linear"
                                        : "w-0"
                                }`}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center items-center w-full">
                        <p className="font-sans my-4 w-3/4 text-center md:text-justify">
                            This search engine project, built using Java and
                            Jetty Servlets, demonstrates the power of web
                            crawling and indexing for fast, efficient retrieval
                            of information. The system starts by web crawling
                            various websites, downloading pages, and processing
                            the content. The engine applies stemming algorithms
                            to reduce words to their base forms, generating an
                            optimized inverted index. When a user submits a
                            query, the engine identifies relevant pages by
                            matching stems and calculating scores based on
                            relevance. Results are then displayed on the front
                            end, offering users accurate and ranked search
                            results.
                        </p>
                    </div>
                    <div className="h-[.0625rem] w-full flex justify-center items-center my-4">
                        <div
                            className={`h-full bg-primary transition-all ${
                                open
                                    ? "w-4/5 transition-all duration-1000 delay-500 ease-linear"
                                    : "w-0"
                            }`}
                        />
                    </div>
                    <div className="grid-cols-1 md:grid-cols-2 gap-2 hidden md:grid">
                        {combinedContent.map((item, index) => (
                            <div
                                key={index}
                                className={`flex items-center justify-center ${
                                    open
                                        ? "opacity-100 translate-y-0"
                                        : "opacity-0  -translate-y-[10%]"
                                } transition-all duration-500`}
                                style={{
                                    transitionDelay: open && `${index * 500}ms`,
                                }}
                            >
                                {item.type === "image" ? (
                                    <img
                                        src={item.content}
                                        alt={project.name + " image " + index}
                                        className="w-full h-auto"
                                    />
                                ) : (
                                    <div className="p-4">
                                        <h1 className="font-bold text-center font-sans">
                                            {item.content.title}:{" "}
                                        </h1>
                                        <p className="font-sans text-center">
                                            {item.content.description}
                                        </p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="grid-cols-1 md:grid-cols-2 gap-2 md:hidden grid">
                        {combinedContentMobile.map((item, index) => (
                            <div
                                key={index}
                                className={`flex items-center justify-center ${
                                    open
                                        ? "opacity-100 translate-y-0"
                                        : "opacity-0  -translate-y-[10%]"
                                } transition-all duration-500`}
                                style={{
                                    transitionDelay: open && `${index * 500}ms`,
                                }}
                            >
                                {item.type === "image" ? (
                                    <img
                                        src={item.content}
                                        alt={project.name + " image " + index}
                                        className="w-full h-auto"
                                    />
                                ) : (
                                    <div className="p-4">
                                        <h1 className="font-bold text-center font-sans">
                                            {item.content.title}:{" "}
                                        </h1>
                                        <p className="font-sans text-center">
                                            {item.content.description}
                                        </p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SearchEngine;
