import React, { useState } from "react";
import Card from "@mui/material/Card";
import { CardContent, CardMedia } from "@mui/material";
import { useTheme } from "@emotion/react";

const ProjectCard = ({ project, openProject }) => {
    const theme = useTheme();

    return (
        <Card
            className="hover:scale-[102%] transition-all ease-in-ou snap-center"
            sx={{
                transition: "ease 300ms all",
                position: "relative",
                cursor: "pointer",
                width: "300px",
                borderRadius: 0,
            }}
            onClick={openProject}
        >
            <CardMedia component="img" image={project.src} alt={project.name} />
            <h1 className="text-xl my-2 mx-4">{project.name}</h1>
            <hr className="mx-4" />
            <CardContent
                className="font-sans leading-6"
                sx={{ paddingBottom: "4rem" }}
            >
                {project.bio}
            </CardContent>

            <div
                className="absolute bottom-4 left-4"
                style={{ width: "calc(100% - 2rem)" }}
            >
                <div className="relative flex justify-start space-x-4">
                    {project.languages &&
                        Object.keys(project.languages).map(
                            (language, index) => (
                                <React.Fragment key={index}>
                                    {project.languages[language]}
                                </React.Fragment>
                            )
                        )}
                </div>
            </div>
        </Card>
    );
};

export default ProjectCard;
