import { useTheme } from "@emotion/react";
import { AppBar } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";

const LandingPage = ({ closeModals }) => {
    const theme = useTheme();

    return (
        <div
            className="w-full bg-background"
            id="home"
            style={{ height: "calc(100vh + 2rem)" }}
        >
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    height: "4rem",
                    backgroundColor: theme.palette.background.default,
                    zIndex: 32,
                }}
            />
        </div>
    );
};

export default LandingPage;
