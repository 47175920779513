import React, { useState, useEffect, useRef } from "react";
import Modal from "../../components/modal/Modal";
import Eridan from "./experiences/Eridan";
import Primrose from "./experiences/Primrose";
import Tutoring from "./experiences/Tutoring";

import FadeInComponent from "../../components/fadein/FadeInComponent";
import { Tooltip, useTheme } from "@mui/material";
import { Link, replace, useNavigate, useNavigation } from "react-router-dom";
import CaretTopRightIcon from "../../icons/TRCaret";
import TygaSmart from "./experiences/TygaSmart";

const Experience = ({ closeModalEffect }) => {
    const [startAnimation, setStartAnimation] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [openedExperience, setOpenedExperience] = useState(-1);
    const navigate = useNavigate();

    const theme = useTheme();

    const experiences = [
        {
            company: "Eridan",
            title: "Software Engineering Intern",
            startTime: "03 / 24",
            endTime: "Now",
            render: <Eridan />,
        },
        {
            company: "TygaSmart",
            title: "Software Engineering Intern",
            startTime: "09 / 24",
            endTime: "Now",
            render: <TygaSmart />,
        },
        {
            company: "Primrose",
            title: "Contract Web Developer",
            startTime: "11 / 23",
            endTime: "01 / 24",
            render: <Primrose />,
        },
        {
            company: "Tutoring",
            title: "Java / Math Tutor",
            startTime: "06 / 19",
            endTime: "10 / 21",
            render: <Tutoring />,
        },
    ];

    const universities = [
        {
            icon: (
                <img
                    className="w-full"
                    src={"/assets/images/university/usfca.webp"}
                />
            ),
        },
    ];

    const experienceRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting && entry.intersectionRatio >= 0.1) {
                    setStartAnimation(true);
                } else {
                    setStartAnimation(false);
                }
            },
            { threshold: 0.1 }
        );

        if (experienceRef.current) {
            observer.observe(experienceRef.current);
        }

        return () => {
            if (experienceRef.current) {
                observer.unobserve(experienceRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setOpenedExperience(-1);
    }, [closeModalEffect]);

    useEffect(() => {
        const url = window.location.href;
        if (url.includes("Experience")) {
            experiences.forEach((exp, index) => {
                if (url.includes(exp.company)) {
                    setOpenedExperience(index);
                }
            });
        } else {
            setOpenedExperience(-1);
        }
    }, [window.location.href]);

    return (
        <div
            id="experience"
            ref={experienceRef}
            className="min-h-screen w-full flex flex-col justify-center items-center bg-primary text-background"
        >
            <Eridan
                open={openedExperience === 0}
                onClose={() => setOpenedExperience(-1)}
            />
            <TygaSmart
                open={openedExperience === 1}
                onClose={() => setOpenedExperience(-1)}
            />
            <Primrose
                open={openedExperience === 2}
                onClose={() => setOpenedExperience(-1)}
            />
            <Tutoring
                open={openedExperience === 3}
                onClose={() => setOpenedExperience(-1)}
            />
            <div className="grid lg:grid-cols-2 grid-cols-1 pb-8 px-4 lg:py-12 lg:px-8 lg:gap-24 mb-24 md:mb-0 flex-1">
                {/* This section should appear below on mobile */}
                <div className="place-content-end flex-col mb-12 lg:mb-32 pt-0 lg:pt-8 space-y-4 h-full order-last lg:order-first">
                    <div className="space-y-4">
                        <FadeInComponent
                            color={"bg-background"}
                            startAnimation={startAnimation}
                        >
                            <div className="h-24 flex lg:justify-start justify-center w-full space-x-4">
                                {universities.map((university, index) => (
                                    <Link
                                        key={index}
                                        to={
                                            "https://www.usfca.edu/arts-sciences/programs/undergraduate/computer-science/program-overview"
                                        }
                                        target="_blank"
                                        className="h-full transition-all group relative flex justify-center items-center space-x-4"
                                    >
                                        <div className="aspect-square h-full">
                                            {university.icon}
                                        </div>
                                        <h1 className="text-2xl relative pr-2">
                                            University of San Francisco
                                            <div className="absolute top-0 right-0 group-hover:opacity-100 opacity-0 transition-all">
                                                <CaretTopRightIcon size={12} />
                                            </div>
                                        </h1>
                                    </Link>
                                ))}
                            </div>
                        </FadeInComponent>
                        <FadeInComponent
                            color={"bg-background"}
                            startAnimation={startAnimation}
                        >
                            <h1 className="text-6xl hidden lg:flex ">
                                Experience
                            </h1>
                        </FadeInComponent>
                        <FadeInComponent
                            color={"bg-background"}
                            startAnimation={startAnimation}
                        >
                            <p className="font-sans leading-normal font-light text-sm lg:text-base">
                                Through both my academic and practical
                                experiences, I have developed a solid foundation
                                in software engineering and web development.
                                With a 3.9 GPA at the University of San
                                Francisco, I have honed the technical and
                                problem-solving skills essential for thriving in
                                fast-paced environments.
                            </p>
                        </FadeInComponent>
                        <FadeInComponent
                            color={"bg-background"}
                            startAnimation={startAnimation}
                        >
                            <p className="font-sans leading-normal font-light text-sm lg:text-base">
                                Alongside my academic work, I’ve applied my
                                skills in professional environments, gaining
                                valuable experience in team collaboration and
                                successfully delivering projects. I strive to
                                continuously learn and adapt so that I can make
                                meaningful contributions to every project I
                                engage in.
                            </p>
                        </FadeInComponent>
                    </div>
                </div>
                {/* This section stays above on mobile */}
                <div className="flex justify-center items-center w-full h-full order-first lg:order-last pt-12 lg:pt-0">
                    <div className="grid grid-cols-1 w-full">
                        {experiences.map((exp, index) => (
                            <button
                                onMouseEnter={() => setHoverIndex(index)}
                                onMouseLeave={() => setHoverIndex(-1)}
                                onClick={() => {
                                    const top = document
                                        .getElementById("experience")
                                        .getBoundingClientRect().top;

                                    if (top > 0) {
                                        window.scrollTo({
                                            top: window.scrollY + top,
                                            behavior: "smooth",
                                        });
                                    }
                                    setOpenedExperience(index);
                                    navigate(`/Experience/${exp.company}`);
                                }}
                                className="text-left w-full mb-4 max-w-[36rem] cursor-pointer mx-auto"
                                key={index}
                            >
                                <FadeInComponent
                                    fullWidth={true}
                                    color={"bg-background"}
                                    startAnimation={startAnimation}
                                >
                                    <div className="w-full">
                                        <div
                                            className={`flex items-center ${
                                                hoverIndex === index ||
                                                openedExperience === index
                                                    ? "italic"
                                                    : "normal"
                                            }`}
                                            style={{
                                                paddingLeft:
                                                    hoverIndex === index ||
                                                    openedExperience === index
                                                        ? "1.5rem"
                                                        : "1rem",
                                                transition:
                                                    "padding-left 0.3s ease",
                                            }}
                                        >
                                            <h1 className="text-4xl lg:text-6xl pr-4 uppercase text-nowrap">
                                                {exp.company}
                                            </h1>
                                            <p className="text-sm lg:text-base text-nowrap ">
                                                {exp.startTime} - {exp.endTime}
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <div className="h-[.0625rem] w-full bg-background mr-2" />
                                            <p className="font-sans font-light text-sm lg:text-base text-nowrap">
                                                {exp.title}
                                            </p>
                                        </div>
                                    </div>
                                </FadeInComponent>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;
