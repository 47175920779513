import React from "react";
import { HashRouter, Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Page from "./pages/Page";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"


const theme = createTheme({
	palette: {
		background: {
			default: '#f2f3f4',
		},
		primary: {
			main: '#1B1B1B',
		},
		secondary: {
			main: '#dcd0ff',
		},
	},
});

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<SpeedInsights/>
			<Analytics/>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Page />} />
					<Route path="/Experience/Eridan" element={<Page />} />
					<Route path="/Experience/TygaSmart" element={<Page />} />
					<Route path="/Experience/Primrose" element={<Page />} />
					<Route path="/Experience/Tutoring" element={<Page />} />
					<Route path="/Projects/Recipe-Social-Media-Web-App" element={<Page />} />
					<Route path="/Projects/Noise-Generator" element={<Page />} />
					<Route path="/Projects/Search-Engine" element={<Page />} />
					<Route path="/Projects/Server-in-C" element={<Page />} />
					<Route path="/About/Video" element={<Page />} />

					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
};

export default App;