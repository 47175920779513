import React, { useEffect, useState } from 'react'

const FadeInComponent = ({ children, color, startAnimation, fullWidth }) => {
    const [animationState, setAnimationState] = useState(0);

    useEffect(() => {
        let timer = undefined
        if (startAnimation) {
            if (animationState < 2) {
                timer = setTimeout(() => {
                    setAnimationState(i => i + 1);
                }, 400);
            }
        } else {
            setAnimationState(0)
        }

        return () => clearTimeout(timer);
    }, [animationState, startAnimation]);

    const states = {
        slide: [
            'w-0 left-0',
            'w-full left-0',
            'w-0 left-full' // Smoother slide transition
        ],
        opacity: [
            'opacity-0',
            'opacity-0', // Added transition for opacity
            'opacity-100' // Fade out after slide completes
        ]
    }

    return (
        <div className={`relative ${fullWidth ? 'w-full' : 'w-fit'} overflow-hidden`}>
            <div
                className={`h-full top-0 ${color} transition-all duration-[400ms] absolute ${states.slide[animationState]}`}
            />
            <div
                className={`relative z-10 ${states.opacity[animationState]}`}
            >
                {children}
            </div>
        </div>
    )
}

export default FadeInComponent
