import React, { useEffect, useRef } from "react";
import Modal from "../../../components/modal/Modal";
import { IconButton, Tooltip } from "@mui/material";
import { FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

const NoiseGenerator = ({ project, open, onClose }) => {
    const descriptions = [
        {
            title: "Socket Creation and Binding",
            description:
                "The server creates a socket, binds it to a specified port, and listens for incoming connections.",
        },
        {
            title: "Polling",
            description:
                "The server uses the poll function to handle multiple clients simultaneously.",
        },
        {
            title: "Request Processing",
            description:
                "The server processes requests by parsing the requested URL, fetching the corresponding file from the server’s directory, and sending it back to the client with appropriate HTTP headers.",
        },
        {
            title: "Connection Handling",
            description:
                "Each client connection is handled in a separate thread to allow for multiple clients to connect to the server simultaneously.",
        },
        {
            title: "File Serving",
            description:
                "The server serves files to clients by reading the requested file from the server’s directory and sending it back to the client with appropriate HTTP headers.",
        },
        {
            title: "Port Configuration",
            description:
                "The server allows for the configuration of the port number on which it listens for incoming connections.",
        },
    ];

    const projectRef = useRef(null);

    useEffect(() => {
        if (open) {
            projectRef.current.scrollTop = 0;
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            percent={100}
            background={"bg-background pt-32"}
            closeIcon
        >
            <div className="p-6 pt-24 overflow-y-auto h-full">
                <h1 className="text-3xl text-primary absolute top-20 w-full text-nowrap inset-0 h-min text-center">
                    {project.name}
                </h1>
                <Link target="_blank" to="https://github.com/BenKamin03/C-Server">
                    <IconButton
                        disableRipple
                        sx={{
                            position: "absolute",
                            top: "5rem",
                            right: "2rem",
                            color: useTheme().palette.primary.main,
                        }}
                    >
                        <FaGithub />
                    </IconButton>
                </Link>
                <div ref={projectRef} className="w-full h-full overflow-y-auto">
                    <div className="flex justify-center items-center w-full space-x-4">
                        <div className="flex justify-center items-center w-4/5 space-x-4">
                            <div
                                className={`h-[.0625rem] bg-primary transition-all ${
                                    open
                                        ? "w-full transition-all duration-[2.5s] delay-500 ease-linear"
                                        : "w-0"
                                }`}
                            />
                            <div className="flex space-x-4">
                                {project.languages &&
                                    Object.keys(project.languages).map(
                                        (language, index) => (
                                            <Tooltip
                                                key={index}
                                                title={language}
                                            >
                                                <div>
                                                    {
                                                        project.languages[
                                                            language
                                                        ]
                                                    }
                                                </div>
                                            </Tooltip>
                                        )
                                    )}
                            </div>
                            <div
                                className={`h-[.0625rem] bg-primary transition-all ${
                                    open
                                        ? "w-full transition-all duration-[2.5s] delay-500 ease-linear"
                                        : "w-0"
                                }`}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center items-center w-full">
                        <p className="font-sans my-4 w-3/4 text-center md:text-justify">
                            This project is a simple HTTP server written in C
                            that handles basic GET requests and serves files to
                            clients. It is designed to create a socket, bind it
                            to a specified port, and listen for incoming
                            connections. The server uses the poll function to
                            handle multiple clients simultaneously. It processes
                            requests by parsing the requested URL, fetching the
                            corresponding file from the server’s directory, and
                            sending it back to the client with appropriate HTTP
                            headers.
                        </p>
                    </div>
                    <div className="h-[.0625rem] w-full flex justify-center items-center my-4">
                        <div
                            className={`h-full bg-primary transition-all ${
                                open
                                    ? "w-4/5 transition-all duration-1000 delay-500 ease-linear"
                                    : "w-0"
                            }`}
                        />
                    </div>
                    <div className="grid-cols-1 md:grid-cols-2 gap-2 hidden md:grid">
                        {descriptions.map((item, index) => (
                            <div
                                key={index}
                                className={`flex items-center justify-center ${
                                    open
                                        ? "opacity-100 translate-y-0"
                                        : "opacity-0  -translate-y-[10%]"
                                } transition-all duration-500`}
                                style={{
                                    transitionDelay: open && `${index * 500}ms`,
                                    minHeight: "25vh",
                                }}
                            >
                                <div className="p-4">
                                    <h1 className="font-bold text-center font-sans">
                                        {item.title}:{" "}
                                    </h1>
                                    <p className="font-sans text-center">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default NoiseGenerator;
