import { useTheme } from "@emotion/react";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import CaretTopRightIcon from "../../icons/TRCaret";

const Navbar = ({ closeModals, downloadResume }) => {
    const [scrollY, setScrollY] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleScroll);
        };
    }, []);

    const maxScroll = window.innerHeight;
    const scrollRatio = Math.min(1, (scrollY + 32) / maxScroll);

    const scrollTo = (link) => {
        closeModals();
        document.getElementById(link).scrollIntoView({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    const leftLinks = [
        <p
            className="cursor-pointer px-2"
            key="resume"
            onClick={downloadResume}
        >
            Resume
        </p>,
        <Link
            target="_blank"
            className="relative group px-2"
            key="linkedin"
            to={"https://linkedin.com/in/benjamin-kamin/"}
        >
            LinkedIn
            <div className="group-hover:opacity-100 absolute top-0 right-0 opacity-0 transition-all">
                <CaretTopRightIcon size={8} />
            </div>
        </Link>,
        <Link
            target="_blank"
            className="relative group px-2"
            key="github"
            to={"https://github.com/BenKamin03"}
        >
            Github
            <div className="group-hover:opacity-100 absolute top-0 right-0 opacity-0 transition-all">
                <CaretTopRightIcon size={8} />
            </div>
        </Link>,
    ];

    const rightLinks = ["Experience", "Projects", "About Me"].map((link) => (
        <p
            key={link}
            style={{ zIndex: 9999 }}
            className="cursor-pointer px-2"
            onClick={() => scrollTo(link.toLowerCase())}
        >
            {link}
        </p>
    ));

    const theme = useTheme();

    const interpolateFontSize = () => {
        const minFontSize = 4; // 2rem
        const maxFontSize = 16; // 11.25vw
        return `calc(${minFontSize}rem + calc(${maxFontSize}vw - ${minFontSize}rem) * ${
            1 - scrollRatio
        })`;
    };

    const fontSize = interpolateFontSize();

    return (
        <AppBar
            position="fixed"
            elevation={(scrollY + 32) / maxScroll >= 1 ? 4 : 0}
            sx={{
                backgroundColor: "transparent", //theme.palette.background.default,
                color: theme.palette.primary.main,
                zIndex: 40,
                height: `calc(100dvh - ${
                    100 * Math.min(1, scrollRatio)
                }dvh + 4rem)`,
            }}
        >
            <div className="w-full h-full relative overflow-clip">
                <Toolbar
                    className="w-full h-16 justify-between flex relative select-none"
                    style={{ zIndex: 9999 }}
                >
                    <div className="lg:flex flex-1 justify-start space-x-2 hidden text-xl">
                        {leftLinks.map((link, index) => (
                            <React.Fragment key={index}>
                                {link}
                                {index !== leftLinks.length - 1 && <h1>\</h1>}
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="lg:flex flex-1 justify-end space-x-2 hidden text-xl">
                        {rightLinks.map((link, index) => (
                            <React.Fragment key={index}>
                                {link}
                                {index !== rightLinks.length - 1 && <h1>/</h1>}
                            </React.Fragment>
                        ))}
                    </div>
                </Toolbar>
                <div className="absolute inset-0 flex justify-center items-center flex-col">
                    <h1
                        className="text-nowrap select-none w-fit"
                        style={{
                            fontSize,
                            cursor: scrollRatio > 0.25 ? "pointer" : "default",
                            zIndex: 10000,
                            wordSpacing: `calc(${fontSize} * -.25)`,
                        }}
                        onClick={() => {
                            if (scrollRatio > 0.25) {
                                scrollTo("home");
                                navigate("/");
                            }
                        }}
                    >
                        BEN KAMIN
                    </h1>
                </div>
                <div className="absolute inset-0 flex justify-center items-center flex-col">
                    <p
                        className="text-nowrap text-xl w-fit"
                        style={{
                            transform: `translateY(calc(5.75vw + 2rem - ${
                                Math.min(1, scrollRatio) * 5.75
                            }vw))`,
                            opacity: Math.max(0, 1 - scrollRatio * 1.25),
                        }}
                    >
                        Full Stack Software Engineer & Web Developer
                    </p>
                </div>
                <div className="absolute inset-0 flex justify-center items-center flex-col">
                    <IconButton
                        className="text-nowrap text-xl transition-all duration-300 ease-in-out bg-black group"
                        disableRipple
                        sx={{
                            transform: `translateY(calc(5.75vw + 4rem - ${
                                Math.min(1, scrollRatio) * 5.75
                            }vw))`,
                            cursor: scrollRatio < 0.5 ? "pointer" : "default",
                            zIndex: 99999,
                            color: theme.palette.primary.main,
                        }}
                        onClick={() => {
                            if (scrollRatio > 0.5) return;
                            document.scrollingElement.scrollTo({
                                top: document.getElementById("experience")
                                    .offsetTop,
                                behavior: "smooth",
                            });
                        }}
                    >
                        <BiChevronDown
                            className="group-hover:animate-bounce-vertical-down"
                            style={{
                                opacity: Math.max(0, 1 - scrollRatio * 2),
                            }}
                        />
                    </IconButton>
                </div>
                <div className="absolute inset-0 flex justify-center items-center flex-col visible lg:hidden">
                    <p
                        className="text-nowrap text-xl transition-opacity duration-300 ease-in-out"
                        style={{
                            transform: `translateY(calc(5.75vw + 8rem - ${
                                Math.min(1, scrollRatio) * 5.75
                            }vw))`,
                            opacity: scrollRatio < 0.5 ? 1 : 0,
                            cursor: scrollRatio < 0.5 ? "pointer" : "default",
                            zIndex: 50,
                        }}
                        onClick={() => {
                            if (scrollRatio > 0.5) return;
                            downloadResume();
                        }}
                    >
                        Resume
                    </p>
                </div>
            </div>
        </AppBar>
    );
};

export default Navbar;
