import React, { useEffect, useRef, useState } from "react";
import LandingPage from "./landing/LandingPage";
import Navbar from "../components/navbar/Navbar";
import Experience from "./experience/Experience";
import Projects from "./projects/Projects";
import AboutMe from "./about-me/AboutMe";
import Footer from "../components/footer/Footer";

const Page = () => {
    const [closeModalEffect, setCloseModalEffect] = useState(false);

    const closeModals = () => {
        setCloseModalEffect((v) => !v);
    };

    const resumeLink = useRef(null);

    useEffect(() => {
        const link = document.createElement("a");
        link.href = "/assets/resume/Ben Kamin - Resume.pdf";
        link.download = "Ben Kamin - Resume.pdf";
        resumeLink.current = link;

        return () => {
            if (resumeLink.current) {
                if (resumeLink.current && resumeLink.current.parentElement) {
                    resumeLink.current.parentElement.removeChild(
                        resumeLink.current
                    );
                }
            }
        };
    }, []);

    const downloadResume = () => {
        resumeLink.current.click();
    };

    useEffect(() => {
        const url = window.location.href;

        setTimeout(() => {
            if (url.includes("Experience")) {
                const element = document.getElementById("experience");
                element.scrollIntoView({ behavior: "instant" });
            } else if (url.includes("Projects")) {
                const element = document.getElementById("projects");
                element.scrollIntoView({ behavior: "instant" });
            } else if (url.includes("About")) {
                const element = document.getElementById("about");
                element.scrollIntoView({ behavior: "instant" });
            }
        }, 100);
    }, []);

    return (
        <div className="font-serifinem relative">
            <Navbar closeModals={closeModals} downloadResume={downloadResume} />
            <LandingPage closeModals={closeModals} />
            <Experience closeModalEffect={closeModalEffect} />
            <Projects closeModalEffect={closeModalEffect} />
            <AboutMe closeModalEffect={closeModalEffect} />
            <Footer downloadResume={downloadResume} />
        </div>
    );
};

export default Page;
