import React, { useRef } from "react";
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5";

const Carousel = ({children}) => {
    const carouselRef = useRef(null);

    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -300, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
    };

    return (
        <div className="relative w-full">
            {/* Left button */}
            <button
                className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2"
                onClick={scrollLeft}
            >
                <IoChevronBackSharp size={30} />
            </button>

            {/* Carousel container */}
            <div
                ref={carouselRef}
                className="overflow-x-scroll gap-12 m-12 pb-6 scroll-smooth snap-x snap-mandatory grid grid-flow-col"
                style={{ scrollBehavior: "smooth" }}
            >
                {/* Carousel items */}
                {children}
            </div>

            {/* Right button */}
            <button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2"
                onClick={scrollRight}
            >
                <IoChevronForwardSharp size={30} />
            </button>
        </div>
    );
};

export default Carousel;
